<template>
  <div class="wameed-dashboard-page-content mb-5">
    <page-header
      :title="$t('offers_page.title')"
      :sub-title="
        $t('offers_page.offers_made') +
        '  ' +
        Object.entries(listOffers)[0][1][0].hr_job_title
      "
      btn
      variant="main"
      :btn-title="$t('common.send')"
      classes=" text-bold-15 text-white "
      :disabled-btn="offersSelection.length <= 0"
      @btnAction="showSendOffers = true"
    />
    <warning-modal
      variant="main"
      variant2="warning"
      icon-name="warning-icon"
      :visible="showSendOffers"
      :title="$t('common.send_offer_title')"
      :sub-title="$t('common.send_offer_subtitle')"
      :btn-title="$t('common.send')"
      @close="showSendOffers = false"
      @submitAction="sendOffers()"
    />
    <div>
      <filter-header
        :content="content"
        @applyFilter="applyFilter"
        @resetFilter="resetFilter"
        @orderApplicants="orderApplicants"
      />
    </div>
    <section
      class="
        wameed-dashboard-page-content_body
        d-flex
        flex-column
        justify-content-between
      "
    >
      <div
        v-if="getAllOffers && getAllOffers.length < 1"
        class="d-flex flex-wrap flex-1 px-0"
      >
        <b-col lg="12" md="12" class="px-0">
          <wameed-no-data
            icon="nodata-icon"
            :title="$t('offers_page.no_data')"
            :sub-title="$t('offers_page.no_data_text')"
          />
        </b-col>
      </div>
      <div
        v-for="(offer, i) in listOffers"
        v-if="getAllOffers && getAllOffers.length > 0"
        :key="i"
        class="d-flex flex-wrap flex-1 px-0"
      >
        <b-col
          v-for="(item, index) in offer"
          :key="index"
          lg="3"
          md="3"
          class="py-3"
        >
          <offers-card
            :id="item.id"
            :title="item.name"
            :sub-title="item.created_at"
            :date="item.created_at"
            :employee-job-title="item.employee_job_title"
            :price="item.price"
            :check-box="true"
            :offer-sent="item.status !== 2"
            :check-box-selection="offersSelection.includes(item.id)"
            @submitAction="item.status == 2 ? offerSelectionFun(item.id) : ''"
          />
        </b-col>
      </div>
      <b-col
        v-if="getAllOffers && getAllOffers.length > 0"
        lg="12"
        md="12"
        class="px-0"
      >
        <wameed-pagination
          v-model="filterData.page"
          :page="filterData.page"
          :total-items="getTotalOffers.totalItems"
          :per_page="filterData.per_page"
          @changePage="changePage"
          @changeCurrentPage="changeCurrentPage"
        />
      </b-col>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import WameedPagination from '@/components/WameedPagination.vue';
import WameedNoData from '@/components/WameedNoData.vue';
import PageHeader from '@/components/PageHeader.vue';
import FilterHeader from '@/components/FilterHeader.vue';
import WTables from '@/components/pages/applicants/WTables.vue';
import offersCard from '@/components/pages/orders/OffersCard.vue';
import WarningModal from '@/components/WarningModal.vue';

export default {
  components: {
    WameedPagination,
    WameedNoData,
    PageHeader,
    FilterHeader,
    // WTables,
    offersCard,
    WarningModal,
  },
  data() {
    return {
      filterData: {
        status: '',
        projects: '',
        page: 1,
        per_page: 10,
        order_by: 'desc',
        search: '',
      },
      content: [
        {
          title: 'المهارات الاساسية',
          key: 'original_skill',
          id: 'id',
          name: 'title',
          count: 'count_applicants',
          selectedData: [],
          data: [],
        },
        {
          title: 'المهارات الفرعية',
          id: 'id',
          name: 'title',
          count: 'count_applicants',
          key: 'other_skills',
          selectedData: [],
          data: [],
        },
        {
          title: 'مستوى الخبرة',
          id: 'id',
          name: 'title',
          count: 'count_applicants',
          key: 'experience',
          selectedData: [],
          data: [
            { title: 'Male', id: 1 },
            { title: 'Female', id: 2 },
          ],
        },
        {
          title: 'الجنس',
          id: 'id',
          name: 'title',
          count: null,
          key: 'gender',
          selectedData: [],
          data: [
            { title: 'Male', id: 1 },
            { title: 'Female', id: 2 },
          ],
        },
      ],
      status: [
        {
          0: 'rejected',
          2: 'under_review',
          3: 'agreed',
        },
        {
          0: 'danger',
          2: 'font-secondary',
          3: 'success',
        },
      ],
      listOffers: [],
      offersSelection: [],
      showSendOffers: false,
    };
  },
  computed: {
    ...mapGetters({
      getAllOffers: 'getAllOffers',
      getTotalOffers: 'getTotalOffers',
    }),
  },
  watch: {
    getAllOffers(data) {
      const grouped = this._.groupBy(data, 'hr_job_title');
      this.listOffers = grouped;
    },
  },
  created() {
    this.filterData.projects = this.$route.params.id;
    this.loadData();
  },
  methods: {
    applyFilter() {
      this.content.forEach((item) => {
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },
    resetFilter() {
      this.content.forEach((item) => {
        item.selectedData = [];
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },
    orderApplicants(item) {
      this.filterData = {
        ...this.filterData,
        ...item,
      };
      this.loadData();
    },
    changeCurrentPage(item) {
      this.filterData = {
        ...this.filterData,
        per_page: item.id,
      };
      this.loadData();
    },
    changePage(event) {
      this.filterData = {
        ...this.filterData,
        page: event,
      };
      this.loadData();
    },
    formatedDate(date) {
      if (date) {
        this.moment.locale(this.$i18n.locale === 'ar' ? 'ar_SA' : 'en_US');
        return this.moment().diff(this.moment(date), 'days') > 7
          ? this.moment(date).format('DD/MM/YYYY')
          : this.moment(date).fromNow(true);
      }
      return '------';
    },
    ...mapActions({
      loadOffers: 'loadOffers',
      sendOffersSelection: 'sendOffersSelection',
    }),
    loadData() {
      this.loadOffers(this.filterData);
    },
    offersDetails(id) {
      this.$router.push({
        name: 'orders-offers-details',
        params: { lang: this.$i18n.locale, id },
      });
    },
    sendOffers() {
      this.sendOffersSelection({
        offers: this.offersSelection,
      }).then(() => {
        this.showSendOffers = false;
        this.$router.push({
          name: 'orders-details',
          params: { lang: this.$i18n.locale, id: this.$route.params.id },
        });
      });
    },
    offerSelectionFun(id) {
      if (!this.offersSelection.includes(id)) {
        this.offersSelection.push(id);
      } else {
        this.offersSelection.splice(this.offersSelection.indexOf(id), 1);
      }
    },
  },
};
</script>
